<template>
    <div>
        <b-container>
            <b-row align-v="stretch" class="full-height justify-content-md-center">
                <b-col col lg="8" md="10">
                    <b-card tag="article" class="v-center flex-row" no-body align="left">
                        <b-card-body>
                            <b-row>
                                <b-col col md="5" lg="6" class="d-none d-md-flex">
                                    <div class="my-auto">
                                        <img src="../assets/logo.png" alt="Login" class="w-100 h-auto">
                                    </div>
                                </b-col>
                                <b-col cols="12" md="7" lg="6">
                                    <ValidationObserver ref="observer" v-slot="{ passes }">
                                        <b-form @submit.prevent="passes(onSubmit)">
                                            <h3 class="mb-4 d-flex">Inicio de sesión</h3>
                                            <ValidationProvider rules="usernameRequired" name="Username" v-slot="{ valid, errors }">
                                                <b-input-group>
                                                    <template v-slot:prepend>
                                                        <b-input-group-text >
                                                            <font-awesome-icon icon="user"></font-awesome-icon>
                                                        </b-input-group-text>
                                                    </template>

                                                    <b-form-input v-model="username" placeholder="Usuario"
                                                                  :state="(errors[0] || loginError) ? false : (valid ? true : null)"></b-form-input>
                                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                                </b-input-group>
                                            </ValidationProvider>

                                            <ValidationProvider rules="passwordRequired" name="Password" v-slot="{ valid, errors }">
                                                <b-input-group class="mt-4">
                                                    <template v-slot:prepend>
                                                        <b-input-group-text >
                                                            <font-awesome-icon icon="lock"></font-awesome-icon>
                                                        </b-input-group-text>
                                                    </template>
                                                    <b-form-input v-model="password" placeholder="Contraseña" type="password"
                                                                  :state="(errors[0] || loginError) ? false : (valid ? true : null)"></b-form-input>
                                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                                </b-input-group>
                                            </ValidationProvider>

                                            <b-row class="mt-2" v-show="errorMessage">
                                                <b-col class="text-danger"><small>{{ errorMessage }}</small></b-col>
                                            </b-row>

                                            <b-row class="mt-4">
                                                <b-col cols="5" offset="7">
                                                    <b-button type="submit" variant="primary" class="mx-auto" block :disabled="loggingIn">
                                                        <template v-if="loggingIn"><b-spinner small></b-spinner></template>
                                                        <template v-else>Ingresar</template>
                                                    </b-button>
                                                </b-col>
<!--                                                <b-col cols="7" style="display: flex; align-items: center; justify-content: flex-end">-->
<!--                                                    <a href="#" class="my-auto" style="font-size: 15px">Olvidé mi contraseña</a><br>-->
<!--                                                </b-col>-->
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
<!--                <b-col cols="12" style="height: 24px; align-self: flex-end;">-->
<!--                    <a href="/legacy/login.html">Versión sencilla</a>-->
<!--                </b-col>-->
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { userValidateUrl } from '@routes';
import * as constants from '@constants';

extend('usernameRequired', {
    ...required,
    message: 'Ingresa el usuario'
});

extend('passwordRequired', {
    ...required,
    message: 'Ingresa la contraseña'
});

export default {
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
            loggingIn: false,
            hasError: false,
            errorMessage: false,
            loginError: false
        };
    },
    methods: {
        onSubmit() {
            this.loggingIn = true;
            this.errorMessage = false;
            this.loginError = false;

            this.axios.post(userValidateUrl(), {
                username: this.username,
                password: this.password
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let user = response.data.user;

                    localStorage.setItem('accessToken', user.credentials.accessToken);
                    localStorage.setItem('refreshToken', user.credentials.refreshToken);
                    localStorage.setItem('clientId', user.credentials.clientId);
                    localStorage.setItem('clientSecret', user.credentials.clientSecret);

                    delete user.credentials;

                    localStorage.setItem('userInfo', JSON.stringify(user));

                    this.$router.push({ name: 'BusinessOrderIndex' });
                } else if ('message' in response.data) {
                    this.errorMessage = response.data.message;
                    this.loginError = true;
                } else {
                    this.errorMessage = constants.DEFAULT_ERROR_MESSAGE;
                }
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                this.loggingIn = false;
            });
        }
    },
    computed: {

    }
};
</script>

<style >
.full-height {
    height: 100vh;
}

.v-center {
    margin-top: 30vh;
}
</style>